import React from "react";

const AddressElk = () => {
  return (
    <>
      <h3>
        Elk County PA CareerLink<sup>&reg;</sup>
      </h3>
      <div>
        <p>
          245 Depot Street
          <br />
          St. Marys, PA 15857
        </p>
        <p>
          Phone:
          <span>1-844-PACALINK</span>
        </p>
      </div>
    </>
  );
};

export default AddressElk;
