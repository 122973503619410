import React from "react";
import { Link } from "gatsby";
import StatBox from "../../components/stat-box";
import PagePhoto from "../../components/page-photo";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import HeroPage from "../../components/hero-page";
import AddressElk from "../../components/address-elk";
import StatBoxes from "../../components/stat-boxes";
import ServiceList from "../../components/service-list";
import { postSecondary } from "../../data/post-secondary";
import { education } from "../../data/education";
import { hospitals } from "../../data/hospitals";
import { chambers } from "../../data/chambers";
import { airports } from "../../data/airports";
import ExplorePhotos from "../../components/explore-photos";
import Seo from "../../components/seo";

const Elk = ({ data }) => {
  const photos = data.allMdx.nodes;

  return (
    <>
      <Seo
        title="Elk County, North Central Pennsylvania"
        description="In addition to being the Southern gateway to the Allegheny National Forest, Elk county is globally recognized as the Powdered Metal Capital of the World. Today, powder metal and carbon industries are thriving, and employ a large percentage of the population in St. Marys, and surrounding areas."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Elk County"
        caption="Photo by Jim Merritt"
        captionColor="black"
        position="45%"
      />

      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>The Elk Capital of Pennsylvania</h2>
            <p>
              Named for the noble animal that once abounded in great numbers, we
              are the home to Pennsylvania’s only elk herd.
            </p>
          </div>

          <div className="intro-text">
            <p>
              In addition to being the Southern gateway to the Allegheny
              National Forest, Elk county is globally recognized as the
              "Powdered Metal Capital of the World."
            </p>

            <p>
              Today, powder metal and carbon industries are thriving, and employ
              a large percentage of the population in St. Marys, and surrounding
              areas.
            </p>
          </div>
        </section>

        <StatBoxes>
          <StatBox number="30,608" text="Total population" />
          <StatBox number="48,886" text="Per capita income" />
          <StatBox number="St. Marys" text="Largest city" />
        </StatBoxes>
      </main>

      <div className="county-employers">
        <section>
          <div className="employers-table-container">
            <h2>Top employers in Elk County</h2>

            <table className="employers">
              <tr>
                <th>Employer</th>
                <th>Industry</th>
              </tr>

              <tr>
                <td>Penn Highlands Elk</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Keystone Powdered Metal Company</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Domtar Paper Company LLC</td>
                <td>
                  <Link to="/work/wood-publishing">
                    Wood, Wood Products and Publishing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>American Axle and Manufacturing</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>SGL Carbon LLC</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Wal-Mart Associates Inc</td>
                <td>Retail</td>
              </tr>

              <tr>
                <td>Saint Marys Area School District</td>
                <td>
                  <Link to="/work/education">Education</Link>
                </td>
              </tr>

              <tr>
                <td>Eastern Sintered Alloys Inc</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Metal Powder Products LLC</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Clarion Sintered Metals Inc</td>
                <td>
                  <Link to="/work/advanced-manufacturing">
                    Advanced Manufacturing
                  </Link>
                </td>
              </tr>

              <tr>
                <td>Dickinson Center, Inc.</td>
                <td>
                  <Link to="/work/health-care">
                    Health Care and Social Assistance
                  </Link>
                </td>
              </tr>
            </table>
          </div>

          <div className="employers-photos">
            <PagePhoto
              image={data.employersImage1.sharp.image}
              caption="Innovative Sintered Metals, Inc., Elk County"
            />
            <PagePhoto
              image={data.employersImage2.sharp.image}
              caption="Elk County Heat Treaters, Elk County (photo by Bradd Celidonia, Third Shift Photography)"
            />
          </div>
        </section>
      </div>

      <main>
        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="Innovative Sintered Metals, Inc., Elk County"
          address=<AddressElk />
        />

        <section>
          <h2>County Services</h2>

          <ServiceList
            title="Education"
            image={data.educationImage.sharp.fluid}
            position="40%"
            county="Elk"
            data={education}
            cols={2}
            headingText="School"
          />

          <ServiceList
            title="Post Secondary Education"
            image={data.postSecondary.sharp.fluid}
            position="42%"
            county="Elk"
            data={postSecondary}
            cols={2}
            headingText="School"
          />

          {/* Start Medical / Community Services */}
          <div className="services-body">
            <div className="services-col">
              <ServiceList
                title="Hospitals"
                image={data.medicalImage.sharp.fluid}
                position="30%"
                county="Elk"
                data={hospitals}
                headingText="Facility"
              />
            </div>

            <div className="services-col">
              <ServiceList
                title="Chambers of Commerce"
                image={data.businessImage.sharp.fluid}
                position="55%"
                county="Elk"
                data={chambers}
                headingText="Service"
              />
            </div>
          </div>

          <ServiceList
            title="Airports"
            image={data.airportImage.sharp.fluid}
            position="55%"
            county="Elk"
            data={airports}
            headingText="Airport"
            cols={2}
          />
        </section>

        <ExplorePhotos photos={photos} county="Elk" />
      </main>
    </>
  );
};

export const query = graphql`
  {
    educationImage: file(
      relativePath: { eq: "neonbrand-zFSo6bnZJTw-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    postSecondary: file(
      relativePath: { eq: "clay-banks-GX8KBbVmC6c-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImage: file(relativePath: { eq: "Elk2.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "Elk2.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
    employersImage1: file(relativePath: { eq: "SinteringPicture.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    employersImage2: file(
      relativePath: {
        eq: "heat-treaters-250_1594605024_445_1594470038_DSC_9554.jpg"
      }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    careerImage: file(relativePath: { eq: "careerlink-photo-elk.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    medicalImage: file(relativePath: { eq: "Making-soup.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    businessImage: file(relativePath: { eq: "APP_279.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    airportImage: file(
      relativePath: { eq: "City-of-St-Marys-airport-2017.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    # slideshow photos
    allMdx(filter: { frontmatter: { county: { eq: "elk" } } }) {
      nodes {
        frontmatter {
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;

export default Elk;
